import { tv, VariantProps } from '@/utils/tv'

export const text = tv({
  slots: {
    base: ['text-pretty']
  },
  variants: {
    size: {
      ['heading-2xl']: {
        base: 'text-heading-2xl'
      },
      ['heading-xl']: {
        base: 'text-heading-md lg:text-heading-lg xl:text-heading-xl'
      },
      ['heading-lg']: {
        base: 'text-heading-lg'
      },
      ['heading-md']: {
        base: 'text-heading-sm lg:text-heading-md'
      },
      ['heading-sm']: {
        base: 'text-heading-xs lg:text-heading-sm'
      },
      ['heading-xs']: {
        base: 'text-heading-xs'
      },
      ['heading-2xs']: {
        base: 'text-heading-3xs sm:text-heading-2xs lg:text-heading-xs'
      },
      ['heading-3xs']: {
        base: 'text-heading-3xs'
      },
      ['body-2xl']: {
        base: 'text-body-2xl'
      },
      ['body-xl']: {
        base: 'text-body-xl'
      },
      ['body-lg']: {
        base: 'text-body-lg'
      },
      ['body-md']: {
        base: 'text-body-md'
      },
      ['body-sm']: {
        base: 'text-body-sm'
      },
      ['body-xs']: {
        base: 'text-body-xs'
      },
      ['tagline-lg']: {
        base: 'text-tagline-lg'
      },
      ['tagline-md']: {
        base: 'text-tagline-md'
      },
      ['tagline-sm']: {
        base: 'text-tagline-sm'
      },
      ['label-md']: {
        base: 'text-label-md'
      },
      ['label-sm']: {
        base: 'text-label-sm'
      }
    },
    uppercase: {
      true: 'uppercase'
    },
    italic: {
      true: 'italic'
    },
    notItalic: {
      true: 'not-italic'
    },
    weight: {
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold'
    }
  },
  defaultVariants: {
    uppercase: false
  }
})

export type TextVariantProps = VariantProps<typeof text>
