import { MutableRefObject } from 'react'
import { KeenSliderHooks, KeenSliderInstance } from 'keen-slider'
import { GalleryReturnVariantProps } from './gallery.variants'
import GalleryArrow from './gallery.arrow'

type GalleryNavProps = {
  styles: GalleryReturnVariantProps
  loaded: boolean
  currentSlide: number
  instanceRef: MutableRefObject<KeenSliderInstance<
    {},
    {},
    KeenSliderHooks
  > | null>
}

const GalleryNav = (props: GalleryNavProps) => {
  const { styles, loaded, instanceRef, currentSlide } = props

  if (loaded && instanceRef.current) {
    return (
      <div className={styles.nav()}>
        <GalleryArrow
          className={styles.arrow({ className: '-left-6' })}
          icon="chevron-left"
          handleSlide={instanceRef.current?.prev}
          disabled={currentSlide === 0}
        />
        <GalleryArrow
          className={styles.arrow({ className: '-right-6' })}
          icon="chevron-right"
          handleSlide={instanceRef.current?.next}
          disabled={
            currentSlide === instanceRef.current.track.details.slides.length - 1
          }
        />
      </div>
    )
  }

  return null
}

export default GalleryNav
