import { useMemo, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { GalleryVariantProps, gallery } from './gallery.variants'

export type useGalleryProps = GalleryVariantProps & {
  className?: string
  hideCaption?: boolean
  data: {
    id: string | number
    title: string
    image: string
  }[]
}

export const useGallery = (props: useGalleryProps) => {
  const {
    className = '',
    navPosition = 'left',
    size = 'cover',
    hideCaption = false,
    data = []
  } = props

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  const openLightboxImage = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    })
  }

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      spacing: 12,
      perView: 2
    },
    breakpoints: {
      '(min-width: 0px)': {
        slides: { perView: 1, spacing: 12 }
      },
      '(min-width: 640px)': {
        slides: { perView: 2, spacing: 12 }
      }
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })

  const styles = useMemo(
    () => gallery({ navPosition, size }),
    [navPosition, size]
  )

  return {
    className,
    styles,
    sliderRef,
    instanceRef,
    loaded,
    currentSlide,
    lightboxController,
    openLightboxImage,
    hideCaption,
    data
  }
}
