import { tv, VariantProps } from '@/utils/tv'

export const conversionFormVariant = tv({
  slots: {
    base: [
      'bg-white',
      'border border-yellow-500/30',
      'p-14',
      'flex flex-col gap-4 shrink-0',
      'rounded-md',
      'w-full max-w-xl'
    ],
    title: [
      'max-w-md',
      'text-center',
      'mx-auto',
      'font-circula',
      'text-green-700'
    ],
    form: ['grid grid-cols-1 gap-6'],
    send: []
  }
})

export type ConversionFormVariantProps = VariantProps<
  typeof conversionFormVariant
>
