export const addSingleMarkers = ({
  map,
  location
}: {
  location: google.maps.LatLngLiteral
  map: google.maps.Map | null | undefined
}) => {
  const marker = new google.maps.Marker({
    position: location,
    map,
    icon: {
      url: '/images/marker.svg',
      scaledSize: new google.maps.Size(50, 50)
    }
  })

  return marker
}
