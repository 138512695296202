import { useEffect, useRef } from 'react'
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '@/config/map'
import { addSingleMarkers } from './add-single-markers'
import { MapVariantProps, mapVariant } from './map.variants'

export type useMapProps = MapVariantProps & {
  className?: string
  zoom?: number
  address?: string
  location?: google.maps.LatLngLiteral
  mapId?: string
}

export const useMap = (props: useMapProps) => {
  const {
    className = '',
    location = DEFAULT_MAP_CENTER,
    mapId = 'map',
    zoom = DEFAULT_MAP_ZOOM,
    ...componentProps
  } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const styles = mapVariant()

  useEffect(() => {
    if (ref.current) {
      const center = location
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        mapId,
        mapTypeControl: false,
        fullscreenControl: false
      })

      addSingleMarkers({ map, location })
    }
  }, [ref, mapId, zoom, location])

  return {
    styles,
    ref,
    mapId,
    ...componentProps
  }
}
