import { cockpitUrl } from '@/utils/env'
import { CockpitError } from './CockpitError'

interface CockpitFetchProps {
  method?: 'GET' | 'POST'
  resource: string
  cache?: RequestCache
  headers?: HeadersInit
  payload?: any
  revalidate?: NextFetchRequestConfig['revalidate']
  tags?: string[]
}

const cockpitFetch = async <T>({
  method = 'GET',
  resource,
  cache = 'force-cache',
  headers,
  payload,
  tags
}: CockpitFetchProps): Promise<{ status: number; body: T } | never> => {
  const endpoint = `${cockpitUrl}/${resource}`

  try {
    const response = await fetch(endpoint, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(payload),
      cache,
      ...(tags && {
        next: {
          tags
        }
      })
    })

    const body = await response.json()

    if (!response.ok) {
      throw new CockpitError(`Failed [${method}] ${endpoint}`, {
        status: response.status,
        body
      })
    }

    return {
      status: response.status,
      body
    }
  } catch (error) {
    if (error instanceof CockpitError) {
      throw new CockpitError(error.message, error.params)
    }

    throw error
  }
}

export { cockpitFetch }
