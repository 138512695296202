import { tv, VariantProps } from '@/utils/tv'

export const mapVariant = tv({
  slots: {
    base: ['relative'],
    map: ['w-full h-64 sm:h-80 lg:h-[460px]']
  }
})

export type MapVariantProps = VariantProps<typeof mapVariant>
