import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { ConversionSchema } from '@/types/forms'
import { messages } from '@/config/messages'
import { conversionFormVariant } from './conversion-form.variants'
import { postConversion } from '@/services/conversion'
import { toast } from 'sonner'

const schema = z.object({
  name: z.string().min(1, { message: messages.validation.required }),
  email: z
    .string()
    .min(1, { message: messages.validation.required })
    .email({ message: messages.validation.email }),
  phone: z.string().min(1, { message: messages.validation.required })
})

export type useConversionFormProps = {
  reference: string
}

export const useConversionForm = ({ reference }: useConversionFormProps) => {
  const styles = conversionFormVariant()

  const form = useForm<ConversionSchema>({ resolver: zodResolver(schema) })

  const onSubmit: SubmitHandler<ConversionSchema> = async (data) => {
    const res = await postConversion({
      ...data,
      origin_id: 1,
      reference
    })

    if (!res.success) {
      toast.error(res.message || 'Ops')
      return
    }

    toast.success(res.message || 'Enviado com sucesso!')
    form.reset()
  }

  return {
    styles,
    form,
    onSubmit
  }
}
