import { SvgName } from '@/svg'
import { Button } from '@/components/ui/button'

type GalleryArrowProps = {
  className?: string
  icon: SvgName
  handleSlide: () => void
  disabled: boolean
}

const GalleryArrow = (props: GalleryArrowProps) => {
  const { className = '', icon, handleSlide, disabled } = props

  return (
    <Button
      className={className}
      color="primary"
      size="md"
      variant="outline"
      onPress={() => handleSlide()}
      isDisabled={disabled}
      startIcon={icon}
      rounded
    />
  )
}

export default GalleryArrow
