'use client'

import { ReactNode } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { Spinner } from '@/components/ui/spinner'
import { Text } from '@/components/ui/text'

type MapWrapperProps = {
  apiKey: string
  children?: ReactNode
}

const render = (status: Status) => {
  return (
    <div className="flex items-center justify-center">
      {status === Status.FAILURE ? (
        <Text as="p">failed</Text>
      ) : (
        <Spinner size="md" color="dark" />
      )}
    </div>
  )
}

const MapWrapper = ({ apiKey, children }: MapWrapperProps) => {
  return (
    <Wrapper apiKey={apiKey} render={render}>
      {children}
    </Wrapper>
  )
}

export default MapWrapper
