import { VariantProps, tv } from '@/utils/tv'

export const gallery = tv({
  slots: {
    base: ['w-full overflow-hidden'],
    container: ['container-fluid', 'relative', 'flex flex-col'],
    gallery: ['relative z-20', '!overflow-visible'],
    item: [
      'relative',
      'bg-white',
      'rounded-lg overflow-hidden',
      'before:content-[""]',
      'before:absolute before:z-10 before:left-0 before:bottom-0',
      'before:w-full before:h-full',
      'before:bg-gradient-to-t before:from-black/50 before:to-transparent',
      'cursor-pointer'
    ],
    info: ['absolute bottom-0 left-0 z-20', 'w-full p-6'],
    title: ['text-yellow-50'],
    figure: ['aspect-w-16 aspect-h-10'],
    cover: ['object-center'],
    nav: ['hidden sm:flex gap-3', 'mb-6'],
    arrow: []
  },
  variants: {
    navPosition: {
      left: {
        nav: ['mr-auto']
      },
      right: {
        nav: ['ml-auto']
      }
    },
    size: {
      cover: {
        cover: ['object-cover']
      },
      contain: {
        item: ['border border-gray-100/50'],
        cover: ['object-contain']
      }
    }
  },
  defaultVariants: {
    navPosition: 'left',
    size: 'cover'
  }
})

export type GalleryVariantProps = VariantProps<typeof gallery>

export type GalleryReturnVariantProps = ReturnType<typeof gallery>
