'use client'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/data/input'
import { Text } from '@/components/ui/text'
import {
  useConversionForm,
  useConversionFormProps
} from './use-conversion-form'

export type ConversionFormProps = useConversionFormProps

const ConversionForm = (props: ConversionFormProps) => {
  const { form, onSubmit, styles } = useConversionForm(props)

  return (
    <div className={styles.base()}>
      <Text
        as="h2"
        size="heading-md"
        weight="medium"
        uppercase
        className={styles.title()}
      >
        Cadastre-se para saber tudo sobre o Lumi
      </Text>
      <form
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        className={styles.form()}
      >
        <Input
          {...form.register('name')}
          label="Nome"
          placeholder="Nome"
          hideLabel
          error={form.formState.errors.name}
        />
        <Input
          {...form.register('email')}
          type="email"
          label="E-mail"
          placeholder="E-mail"
          hideLabel
          error={form.formState.errors.email}
        />
        <Input
          {...form.register('phone')}
          type="tel"
          label="Telefone"
          placeholder="Telefone"
          hideLabel
          error={form.formState.errors.phone}
        />
        <Button
          className={styles.send()}
          type="submit"
          loading={form.formState.isSubmitting}
          loadingLabel="Enviando..."
          variant="solid"
          weight="semibold"
          color="primary"
          fullWidth
          uppercase
        >
          Quero saber mais
        </Button>
      </form>
    </div>
  )
}

export default ConversionForm
