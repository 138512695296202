import {
  CockpitFormErrorOperation,
  CockpitFormSuccessOperation
} from '@/services/types'
import { ConversionSchema } from '@/types/forms'
import { cockpitFetch } from '../client'

const postConversion = async (
  payload: ConversionSchema
): Promise<CockpitFormSuccessOperation | CockpitFormErrorOperation> => {
  try {
    const res = await cockpitFetch<
      CockpitFormSuccessOperation | CockpitFormErrorOperation
    >({
      method: `POST`,
      resource: `tenho-interesse`,
      payload
    })

    return res.body
  } catch (error: any) {
    return error?.params?.body as CockpitFormErrorOperation
  }
}

export { postConversion }
