'use client'

import NextImage from 'next/image'
import FsLightbox from 'fslightbox-react'
import { Text } from '@/components/ui/text'
import GalleryNav from './gallery.nav'
import { useGallery, useGalleryProps } from './use-gallery'

export type GalleryProps = useGalleryProps

const Gallery = (props: GalleryProps) => {
  const {
    styles,
    className,
    sliderRef,
    instanceRef,
    currentSlide,
    loaded,
    lightboxController,
    openLightboxImage,
    hideCaption,
    data
  } = useGallery(props)

  return (
    <>
      <div className={styles.base({ className })}>
        <div className={styles.container()}>
          <GalleryNav
            styles={styles}
            currentSlide={currentSlide}
            loaded={loaded}
            instanceRef={instanceRef}
          />
          <div
            ref={sliderRef}
            className={styles.gallery({
              className: `keen-slider`
            })}
          >
            {data.map(({ id, image, title }, index) => (
              <div
                key={`gallery-${id}`}
                className={styles.item({ className: 'keen-slider__slide' })}
                onClick={() => openLightboxImage(index + 1)}
              >
                {!hideCaption && (
                  <div className={styles.info()}>
                    <Text
                      weight="bold"
                      size="body-xl"
                      className={styles.title()}
                    >
                      {title}
                    </Text>
                  </div>
                )}
                <div className={styles.figure()}>
                  <NextImage
                    className={styles.cover()}
                    src={image}
                    alt={title}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FsLightbox
        type="image"
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={data.map(({ image }) => image)}
      />
    </>
  )
}

export default Gallery
