'use client'

import { useMap, useMapProps } from './use-map'

export type MapProps = useMapProps

const Map = (props: MapProps) => {
  const { ref, styles, mapId } = useMap(props)

  return (
    <div className={styles.base()}>
      <div ref={ref} id={mapId} className={styles.map()} />
    </div>
  )
}

export default Map
